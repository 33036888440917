/* istanbul ignore file */
import CategoryState from './category.state';
import Category from 'model/category';
import CategoryImp from 'model/category.imp';

export default class CategoryStateImp implements CategoryState {
    category: Category;

    constructor(category: Category = new CategoryImp()) {
        this.category = category;
    }
}
