import MainAction from '../model/main.action';
import {UserActionTypesEnum} from './types';
import UserState from './model/user.state';
import UserStateImp from './model/user.state.imp';
import {SetUserDataAction} from './actions';
import {ReduxTypesEnum} from '../model/types';

const initialState: UserState = new UserStateImp();

const userReducer = (state = initialState, action: MainAction) => {
    switch (action.type) {
        case ReduxTypesEnum.RESET: {
            return new UserStateImp();
        }
        case UserActionTypesEnum.SET_DATA: {
            return Object.assign({}, state, {
                user: (action as SetUserDataAction).user
            });
        }
        default:
            return state;
    }
};

export default userReducer;
