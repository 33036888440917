/* istanbul ignore file */
import MainAction from '../model/main.action';
import {GrowlSuccessActionTypesEnum} from './types';

export interface ShowGrowlSuccessMessageAction extends MainAction {
	message: string;
}

export const SHOW_GROWL_SUCCESS_MESSAGE_ACTION: ShowGrowlSuccessMessageAction = {
	type: GrowlSuccessActionTypesEnum.SHOW_SUCCESS_MESSAGE,
	message: ''
};

export const HIDE_GROWL_SUCCESS_MESSAGE_ACTION: MainAction = {
	type: GrowlSuccessActionTypesEnum.HIDE_SUCCESS_MESSAGE
};
