/* istanbul ignore file */
import UserState from './user.state';
import User from 'model/user';
import UserImp from 'model/user.imp';

export default class UserStateImp implements UserState {
	user: User;
	isLoggedIn: boolean;

	constructor(user: User = new UserImp(), isLoggedIn: boolean = false) {
		this.user = user;
		this.isLoggedIn = isLoggedIn;
	}
}
