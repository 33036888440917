/* istanbul ignore file */
export enum FieldTypesEnum {
    BOOLEAN = 1,
    EMAIL = 2,
    NUMBER = 3,
    PHONE = 4,
    STRING = 5,
    ZIP_CODE = 6,
    SELECT = 7,
    CUPS_POWER = 8,
    CUPS_GAS = 9,
    FILES = 10,
    BANK_ACCOUNT = 11,
    NATIONAL_ID = 12,
    CIF = 13
}
