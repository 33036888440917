import React, {FunctionComponent, ReactElement, useState} from 'react';
import './cookies.component.scss';
import icons from 'icons/sprite.svg';

const CookiesComponent: FunctionComponent = (): ReactElement | null => {
	const [ hideCookies, setHideCookies ] = useState(window.localStorage.CLUB_SELECTRA_COOKIES);

	if (hideCookies) {
		return null;
	}

	const acceptCookies = (): void => {
		window.localStorage.CLUB_SELECTRA_COOKIES = true;
		setHideCookies(true);
	};

	return <div className="cookies-component">
		<div className="container">
			<svg className="icon mobile-hidden">
				<use xlinkHref={`${icons}#icon-circle-info-outlined`}/>
			</svg>
			<p>
				Este sitio web utiliza Cookies, tanto propias como de terceros. Si continúa navegando acepta su uso. Puede configurar, rechazar las cookies, u obtener más información clicando en "configurar".
				<a href="https://selectra.es/politica-cookies" target="_blank" rel="noopener noreferrer"> Configurar</a>
			</p>
			<div className="buttons">
				<button className="btn btn--sm btn--success" onClick={acceptCookies}>Acepto</button>
			</div>
		</div>
	</div>;
};

export default CookiesComponent;
