import React, {FunctionComponent, ReactElement} from 'react';
import './footer.component.scss';
import {LazyLoadImage} from 'react-lazy-load-image-component';

const FooterComponent: FunctionComponent = (): ReactElement => {
	return <div className="footer-component">
		<div className="container policies">
			<a href="https://selectra.es/politica-privacidad" target="_blank" rel="noopener noreferrer">Política de privacidad</a>
			<span> | </span>
			<a href="https://selectra.es/aviso-legal" target="_blank" rel="noopener noreferrer">Aviso legal</a>
			<span> | </span>
			<a href="https://selectra.es/politica-cookies" target="_blank" rel="noopener noreferrer">Política de cookies</a>
		</div>
		<div className="footer-image">
			<LazyLoadImage alt="Selectra" src={process.env.PUBLIC_URL + '/selectra-light-logo.svg'} />
			<p>© { new Date().getFullYear() } - Todos los derechos reservados</p>
		</div>
	</div>;
};

export default FooterComponent;
