/* istanbul ignore file */
import React, {ReactElement} from 'react';
import FaqElement from './faq-element';
import {JsonObject, JsonProperty} from 'json2typescript';
import {ReactElementDeserializer} from './deserializers/react-element.deserializer';

@JsonObject('FaqElementImp')
export default class FaqElementImp implements FaqElement {

	@JsonProperty('title', String)
	title: string;

	@JsonProperty('text', ReactElementDeserializer)
	text: ReactElement;

	constructor(title: string = '', text: ReactElement = <div/>) {
		this.title = title;
		this.text = text;
	}
}
