import {ChangeEvent} from 'react';
import Dictionary from 'model/dictionary';
import {FieldTypesEnum} from '../model/field-types.enum';
import {
    formatCUPS,
    formatPhone,
    validateBankAccount,
    validateCUPS,
    validateEmail,
    validateNationalId,
    validatePhone, validCIF
} from './validators.service';
import FormField from '../model/form-field';
import ValidatedForm from '../model/validated-form';
import ValidatedFormImp from '../model/validated-form.imp';

export const validateForm = (form: Dictionary<FormField>, formErrors: Dictionary<string>, requiredFields?: string[]): ValidatedForm => {
    const newFormErrors = { ...formErrors };

    Object.keys(form).forEach((key: string) => {
        const field: FormField = form[key];

        newFormErrors[key] = getFieldErrorMessage(field, requiredFields ? requiredFields.includes(key) : true);
    });

    return new ValidatedFormImp(newFormErrors, formErrorsIsEmpty(newFormErrors));
};

export const getFieldErrorMessage = (field: FormField, required: boolean): string => {
    switch (field.type) {
        case FieldTypesEnum.BANK_ACCOUNT:
            field.value = field.value.toUpperCase();
            return getValueValidateErrorMessage(field, required, validateBankAccount, 'IBAN incorrecto');
        case FieldTypesEnum.BOOLEAN:
            return getValueErrorMessage(field, required, 'Debes aceptar las condiciones.');
        case FieldTypesEnum.CUPS_POWER:
        case FieldTypesEnum.CUPS_GAS:
            field.value = formatCUPS(field.value, field.type);
            return getValueValidateErrorMessage(field, required, validateCUPS, 'CUPS incorrecto');
        case FieldTypesEnum.EMAIL:
            return getValueValidateErrorMessage(field, required, validateEmail, 'Email incorrecto');
        case FieldTypesEnum.FILES:
            return getFilesErrorMessage(field, required);
        case FieldTypesEnum.NATIONAL_ID:
            return getValueValidateErrorMessage(field, required, validateNationalId, 'DNI incorrecto');
        case FieldTypesEnum.CIF:
            return getValueValidateErrorMessage(field, required, validCIF, 'CIF incorrecto');
        case FieldTypesEnum.NUMBER:
            return getNumberErrorMessage(field, required);
        case FieldTypesEnum.PHONE:
            field.value = formatPhone(field.value);
            return getValueValidateErrorMessage(field, required, validatePhone, 'Teléfono incorrecto');
        case FieldTypesEnum.SELECT:
            return getSelectErrorMessage(field, required);
        case FieldTypesEnum.STRING:
            return getValueErrorMessage(field, required);
        case FieldTypesEnum.ZIP_CODE:
            return getZipCodeErrorMessage(field, required);
    }
};

const getValueErrorMessage = (field: FormField, required: boolean, message?: string): string => {
    return field.value ? (field.maxLength && field.value.length > field.maxLength ? `El campo no puede tener más de ${field.maxLength} caracteres` : '') : (required ? message || 'Campo obligatorio' : '');
};

const getValueValidateErrorMessage = (field: FormField, required: boolean, validate: Function, message: string): string => {
    return field.value ? (validate(field.value) ? '' : message) : (required ? 'Campo obligatorio' : '');
};

export const getNumberErrorMessage = (field: FormField, required: boolean): string => {
    return field.value.toString().length !== 0 ? checkNumberFormat(field) : required ? 'Campo obligatorio' : '';
};

export const getFilesErrorMessage = (field: FormField, required: boolean): string => {
    return field.value.length ? '' : (required ? 'Adjunte por lo menos un archivo' : '');
};

const getZipCodeErrorMessage = (field: FormField, required: boolean): string => {
    return field.value.toString().length !== 0 ? (field.value.toString().length !== 5 ? 'El código postal consta de 5 dígitos' : checkNumberFormat(field)) : (required ? 'Campo obligatorio' : '');
};

const getSelectErrorMessage = (field: FormField, required: boolean): string => {
    return !field.value.value ? (required ? 'Selecciona una opción' : '') : '';
};

const checkNumberFormat = (field: FormField): string => {
    return isNaN(field.value) ? 'Formato incorrecto' : checkNumberMinMax(field);
};

const checkNumberMinMax = (field: FormField): string => {
    if (field.min !== undefined && field.max !== undefined) {
        return field.value < field.min ? 'El valor mínimo es ' + field.min : field.value > field.max ? 'El valor máximo es ' + field.max : '';
    } else if (field.min !== undefined) {
        return field.value >= field.min ? '' : 'El valor mínimo es ' + field.min;
    } else if (field.max !== undefined) {
        return field.value <= field.max ? '' : 'El valor máximo es ' + field.max;
    } else {
        return '';
    }
};

export const handleChange = (e: ChangeEvent<HTMLInputElement>, form: Dictionary<FormField>, setForm: Function, formErrors: Dictionary<string>, setFormErrors: Function): void => {
    e.preventDefault();
    const formCopy = {...form};
    const formErrorsCopy = {...formErrors};

    formCopy[e.target.name].value = e.target.value;
    formErrorsCopy[e.target.name] = '';

    setForm(formCopy);
    setFormErrors(formErrorsCopy);
};

export const handleSelectChange = (fieldName: string, selectOption: any, form: Dictionary<FormField>, setForm: Function, formErrors: Dictionary<string>, setFormErrors: Function, required: boolean = true): void => {
    const formCopy = {...form};
    const formErrorsCopy = {...formErrors};
    formCopy[fieldName].value = selectOption;
    formErrorsCopy[fieldName] = required && !formCopy[fieldName].value ? 'Selecciona una opción.' : '';

    setForm(formCopy);
    setFormErrors(formErrorsCopy);
};

export const handleCheckboxChange = (fieldName: string, form: Dictionary<FormField>, setForm: Function, formErrors: Dictionary<string>, setFormErrors: Function, required: boolean = true): void => {
    const formCopy = {...form};
    const formErrorsCopy = {...formErrors};
    formCopy[fieldName].value = !formCopy[fieldName].value;
    formErrorsCopy[fieldName] = required && !formCopy[fieldName].value ? 'Debes aceptar las condiciones.' : '';

    setForm(formCopy);
    setFormErrors(formErrorsCopy);
};

export const handleBlur = (e: ChangeEvent<HTMLInputElement>, requiredField: boolean, form: Dictionary<FormField>, formErrors: Dictionary<string>, setFormErrors: Function): void => {
    e.preventDefault();
    const { name } = e.target;
    const formErrorsCopy = {...formErrors};
    formErrorsCopy[name] = getFieldErrorMessage(form[name], requiredField);
    setFormErrors(formErrorsCopy);
};

export const formErrorsIsEmpty = (formErrors: Dictionary<string>): boolean => {
    return Object.keys(formErrors).filter((key: string) => formErrors[key] && formErrors[key].length > 0).length === 0;
};
