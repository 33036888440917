import MainAction from '../model/main.action';
import {GrowlSuccessActionTypesEnum} from './types';
import GrowlSuccessState from './model/growl-success.state';
import GrowlSuccessStateImp from './model/growl-success.state.imp';
import {ReduxTypesEnum} from '../model/types';
import {ShowGrowlSuccessMessageAction} from './actions';

const initialState: GrowlSuccessState = new GrowlSuccessStateImp();

const growlSuccessReducer = (state = initialState, action: MainAction) => {
	switch (action.type) {
		case ReduxTypesEnum.RESET: {
			return new GrowlSuccessStateImp();
		}
		case GrowlSuccessActionTypesEnum.SHOW_SUCCESS_MESSAGE: {
			const messages = [...state.messages];
			messages.push((action as ShowGrowlSuccessMessageAction).message);

			return Object.assign({}, state, {
				messages
			});
		}
		case GrowlSuccessActionTypesEnum.HIDE_SUCCESS_MESSAGE: {
			const messages = [...state.messages];
			messages.shift();

			return Object.assign({}, state, {
				messages
			});
		}
		default:
			return state;
	}
};

export default growlSuccessReducer;
