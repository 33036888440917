/* istanbul ignore file */
import MediaElement from './media-element';
import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('MediaElementImp')
export default class MediaElementImp implements MediaElement {

	@JsonProperty('src', String, true)
	src: string;

	@JsonProperty('link', String, true)
	link: string;

	constructor(src: string = '', link: string = '') {
		this.src = src;
		this.link = link;
	}
}
