/* istanbul ignore file */
import HireState from './hire.state';

export default class HireStateImp implements HireState {
	wrongUser: boolean;
	wrongOffer: boolean;
	expiredOffer: boolean;

	constructor(wrongUser: boolean = false, wrongOffer: boolean = false, expiredOffer: boolean = false) {
		this.wrongUser = wrongUser;
		this.wrongOffer = wrongOffer;
		this.expiredOffer = expiredOffer;
	}
}
