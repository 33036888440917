import {JsonConverter, JsonCustomConvert} from 'json2typescript';

@JsonConverter
export class NullableDeserializer implements JsonCustomConvert<String> {
	deserialize = (value: any): String => {
		return String(value);
	};

	serialize = (value: string): String | null => {
		return value ? String(value) : null;
	};
}
