/* istanbul ignore file */
import GrowlSuccessState from './growl-success.state';

export default class GrowlSuccessStateImp implements GrowlSuccessState {
	messages: string[];

	constructor(messages: string[] = []) {
		this.messages = messages;
	}
}
