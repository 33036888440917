import {JsonConverter, JsonCustomConvert} from 'json2typescript';
import OfferRate from 'components/hire/model/offer-rate';
import OfferRateImp from 'components/hire/model/offer-rate.imp';
import OfferDetailImp from 'components/hire/model/offer-detail.imp';
import React from 'react';
import { OFFER_CONSTANTS } from 'components/hire/components/offer/config/offer.config';

@JsonConverter
export class GasRatesDeserializer implements JsonCustomConvert<OfferRate[]> {
	deserialize = (rates: any = {}): OfferRate[] => {
		return [
			new OfferRateImp(
				OFFER_CONSTANTS.GAS_RATES.RL_1,
				[
					new OfferDetailImp('Término fijo', <span><strong>{ rates.fixed31 || '-' }</strong> €/mes</span>),
					new OfferDetailImp('Término de consumo', <span><strong>{ rates.consumption31 || '-' }</strong> €/kWh</span>)
				]
			), new OfferRateImp(
				OFFER_CONSTANTS.GAS_RATES.RL_2,
				[
					new OfferDetailImp('Término fijo', <span><strong>{ rates.fixed32 || '-' }</strong> €/mes</span>),
					new OfferDetailImp('Término de consumo', <span><strong>{ rates.consumption32 || '-' }</strong> €/kWh</span>)
				]
			)
		];
	};

	serialize = (rates: OfferRate[] = []): string => {
		return rates.toString();
	};
}
