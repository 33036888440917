/* istanbul ignore file */
import {combineReducers} from 'redux';
import categoryReducer from './category/reducer';
import growlReducer from './growl/reducer';
import hireReducer from './hire/reducer';
import loadingReducer from './loading/reducer';
import modalReducer from './modal/reducer';
import offerReducer from './offer/reducer';
import userReducer from './user/reducer';
import growlSuccessReducer from './growl-success/reducer';

export const mainReducer = combineReducers({
    category: categoryReducer,
    growl: growlReducer,
    hire: hireReducer,
    loading: loadingReducer,
    modal: modalReducer,
    offer: offerReducer,
    user: userReducer,
    growlSuccess: growlSuccessReducer
});
