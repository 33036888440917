/* istanbul ignore file */
import GrowlState from './growl.state';

export default class GrowlStateImp implements GrowlState {
	messages: string[];

	constructor(messages: string[] = []) {
		this.messages = messages;
	}
}
