/* istanbul ignore file */
import React from 'react';
import Category from './category';
import {JsonObject, JsonProperty} from 'json2typescript';
import {ReactElement} from 'react';
import HowWorksElement from './how-works-element';
import MediaElement from './media-element';
import FaqElement from './faq-element';
import {ReactElementDeserializer} from './deserializers/react-element.deserializer';
import FaqElementImp from './faq-element.imp';
import MediaElementImp from './media-element.imp';
import HowWorksElementImp from './how-works-element.imp';

@JsonObject('CategoryImp')
export default class CategoryImp implements Category {

	@JsonProperty('id', String, true)
	id: string;

	@JsonProperty('name', String, true)
	name: string;

	@JsonProperty('group_spain', String, true)
	groupSpain: string;

	@JsonProperty('header_logo', String, true)
	headerLogo: string;

	@JsonProperty('hire_header_image', String, true)
	hireHeaderImage: string;

	@JsonProperty('lead_source', String, true)
	leadSource: string;

	@JsonProperty('page_title', String, true)
	pageTitle: string;

	@JsonProperty('home_title_background_image', String, true)
	homeTitleBackgroundImage: string;

	@JsonProperty('home_title', String, true)
	homeTitle: string;

	@JsonProperty('home_title_outlined', String, true)
	homeTitleOutlined: string;

	@JsonProperty('home_subtitle', ReactElementDeserializer, true)
	homeSubtitle: ReactElement;

	@JsonProperty('home_checks', [String], true)
	homeChecks: string[];

	@JsonProperty('inscribe_button', String, true)
	inscribeButton: string;

	@JsonProperty('how_works_title', String, true)
	howWorksTitle: string;

	@JsonProperty('how_works_elements', [HowWorksElementImp], true)
	howWorksElements: HowWorksElement[];

	@JsonProperty('media_title', String, true)
	mediaTitle: string;

	@JsonProperty('media_elements', [MediaElementImp], true)
	mediaElements: MediaElement[];

	@JsonProperty('contact_title', String, true)
	contactTitle: string;

	@JsonProperty('contact_schedule', ReactElementDeserializer, true)
	contactSchedule: ReactElement;

	@JsonProperty('contact_phone', String, true)
	contactPhone: string;

	@JsonProperty('contact_email', String, true)
	contactEmail: string;

	@JsonProperty('faq_title', String, true)
	faqTitle: string;

	@JsonProperty('faq_elements', [FaqElementImp], true)
	faqElements: FaqElement[];

	@JsonProperty('thanks_title', String, true)
	thanksTitle: string;

	@JsonProperty('thanks_subtitle', ReactElementDeserializer, true)
	thanksSubtitle: ReactElement;

	@JsonProperty('inscription_title', String, true)
	inscriptionTitle: string;

	@JsonProperty('inscription_subtitle', ReactElementDeserializer, true)
	inscriptionSubtitle: ReactElement;

	@JsonProperty('inscribed_text', String, true)
	inscribedText: string;

	@JsonProperty('inscribed_image', String, true)
	inscribedImage: string;

	@JsonProperty('no_business', Boolean, true)
	noBusiness: boolean;

	@JsonProperty('business', Boolean, true)
	business: boolean;

	@JsonProperty('power_allowed', Boolean, true)
	powerAllowed: boolean;

	@JsonProperty('gas_allowed', Boolean, true)
	gasAllowed: boolean;

	@JsonProperty('dual_allowed', Boolean, true)
	dualAllowed: boolean;

	@JsonProperty('conditions_text', String, true)
	conditionsText: string;

	@JsonProperty('conditions_url', String, true)
	conditionsUrl: string;

	@JsonProperty('newsletter_text', String, true)
	newsletterText: string;

	@JsonProperty('gtm_event', String, true)
	gtmEvent: string;

	@JsonProperty('gtm_register_page', String, true)
	gtmRegisterPage: string;

	@JsonProperty('gtm_cups_page', String, true)
	gtmCupsPage: string;

	@JsonProperty('gtm_register_hire_page', String, true)
	gtmRegisterHirePage: string;

	@JsonProperty('gtm_cups_hire_page', String, true)
	gtmCupsHirePage: string;

	@JsonProperty('gtm_attach_invoice', String, true)
	gtmAttachInvoice: string;

	@JsonProperty('menu_inscribe_button', String, true)
	menuInscribeButton: string;

	@JsonProperty('about_us_title', String, true)
	aboutUsTitle: string;

	@JsonProperty('about_us_image', String, true)
	aboutUsImage: string;

	@JsonProperty('about_us_text', ReactElementDeserializer, true)
	aboutUsDescription: ReactElement;

	constructor(id: string = '', name: string = '', groupSpain: string = '', headerLogo: string = '', hireHeaderImage: string = '', leadSource: string = '',
				pageTitle: string = '', homeTitleBackgroundImage: string = '', homeTitle: string = '', homeTitleOutlined: string = '',
				homeSubtitle: ReactElement = <div/>, homeChecks: string[] = [], inscribeButton: string = '', howWorksTitle: string = '',
				howWorksElements: HowWorksElement[] = [], mediaTitle: string = '', mediaElements: MediaElement[] = [],
				contactTitle: string = '', contactSchedule: ReactElement = <div/>, contactPhone: string = '', contactEmail: string = '',
				faqTitle: string = '', faqElements: FaqElement[] = [], thanksTitle: string = '', thanksSubtitle: ReactElement = <div/>,
				inscriptionTitle: string = '', inscriptionSubtitle: ReactElement = <div/>, inscribedText: string = '',
				inscribedImage: string = '', noBusiness: boolean = true, business: boolean = true, powerAllowed: boolean = true,
				gasAllowed: boolean = true, dualAllowed: boolean = true, conditionsText: string = '', conditionsUrl: string = '',
				newsletterText: string = '', gtmEvent: string = '', gtmRegisterPage: string = '', gtmCupsPage: string = '',
				gtmRegisterHirePage: string = '', gtmCupsHirePage: string = '', gtmAttachInvoice: string = '', menuInscribeButton: string = '',
				aboutUsTitle = '', aboutUsImage = '', aboutUsDescription: ReactElement = <div/>) {
		this.id = id;
		this.name = name;
		this.groupSpain = groupSpain;
		this.headerLogo = headerLogo;
		this.hireHeaderImage = hireHeaderImage;
		this.leadSource = leadSource;
		this.pageTitle = pageTitle;
		this.homeTitleBackgroundImage = homeTitleBackgroundImage;
		this.homeTitle = homeTitle;
		this.homeTitleOutlined = homeTitleOutlined;
		this.homeSubtitle = homeSubtitle;
		this.homeChecks = homeChecks;
		this.inscribeButton = inscribeButton;
		this.howWorksTitle = howWorksTitle;
		this.howWorksElements = howWorksElements;
		this.mediaTitle = mediaTitle;
		this.mediaElements = mediaElements;
		this.contactTitle = contactTitle;
		this.contactSchedule = contactSchedule;
		this.contactPhone = contactPhone;
		this.contactEmail = contactEmail;
		this.faqTitle = faqTitle;
		this.faqElements = faqElements;
		this.thanksTitle = thanksTitle;
		this.thanksSubtitle = thanksSubtitle;
		this.inscriptionTitle = inscriptionTitle;
		this.inscriptionSubtitle = inscriptionSubtitle;
		this.inscribedText = inscribedText;
		this.inscribedImage = inscribedImage;
		this.noBusiness = noBusiness;
		this.business = business;
		this.powerAllowed = powerAllowed;
		this.gasAllowed = gasAllowed;
		this.dualAllowed = dualAllowed;
		this.conditionsText = conditionsText;
		this.conditionsUrl = conditionsUrl;
		this.newsletterText = newsletterText;
		this.gtmEvent = gtmEvent;
		this.gtmRegisterPage = gtmRegisterPage;
		this.gtmCupsPage = gtmCupsPage;
		this.gtmRegisterHirePage = gtmRegisterHirePage;
		this.gtmCupsHirePage = gtmCupsHirePage;
		this.gtmAttachInvoice = gtmAttachInvoice;
		this.menuInscribeButton = menuInscribeButton;
		this.aboutUsTitle = aboutUsTitle;
		this.aboutUsImage = aboutUsImage;
		this.aboutUsDescription = aboutUsDescription;
	}
}
