/* istanbul ignore file */
import MainAction from '../model/main.action';
import {GrowlActionTypesEnum} from './types';

export interface ShowGrowlMessageAction extends MainAction {
	message: string;
}

export const SHOW_GROWL_MESSAGE_ACTION: ShowGrowlMessageAction = {
	type: GrowlActionTypesEnum.SHOW_MESSAGE,
	message: ''
};

export const HIDE_GROWL_MESSAGE_ACTION: MainAction = {
	type: GrowlActionTypesEnum.HIDE_MESSAGE
};
