/* istanbul ignore file */
import Address from './address';
import {JsonObject, JsonProperty} from 'json2typescript';
import {ServicesEnum} from 'components/inscription/model/services.enum';
import {NullableDeserializer} from './deserializers/nullable.deserializer';

@JsonObject('AddressImp')
export default class AddressImp implements Address {

	@JsonProperty('id', Number, true)
	id?: number;

	@JsonProperty('zoho_id', String, true)
	zohoId?: string;

	@JsonProperty('service_type', String, true)
	serviceType: string;

	@JsonProperty('street_type', String, true)
	streetType: string;

	@JsonProperty('street', String, true)
	street: string;

	@JsonProperty('province', String, true)
	province: string;

	@JsonProperty('municipality', String, true)
	municipality: string;

	@JsonProperty('locality', String, true)
	locality: string;

	@JsonProperty('number', String, true)
	number: string;

	@JsonProperty('block', String, true)
	block: string;

	@JsonProperty('stair', String, true)
	stair: string;

	@JsonProperty('floor', String, true)
	floor: string;

	@JsonProperty('door', String, true)
	door: string;

	@JsonProperty('zip_code', String, true)
	zipCode: string;

	@JsonProperty('power_access_rate', String, true)
	powerAccessRate: string;

	@JsonProperty('hired_power', String, true)
	hiredPower: string;

	@JsonProperty('power_cups', String, true)
	powerCups: string;

	@JsonProperty('gas_access_rate', String, true)
	gasAccessRate: string;

	@JsonProperty('gas_cups', String, true)
	gasCups: string;

	@JsonProperty('social_bonus', Boolean, true)
	socialBonus: boolean;

	@JsonProperty('lead_source', String, true)
	leadSource: string;

	@JsonProperty('city_code', String, true)
	groupSpain: string;

	@JsonProperty('offer_id', String, true)
	offerId: string;

	@JsonProperty('offer_url', String, true)
	offerUrl: string;

	@JsonProperty('offer_spain', NullableDeserializer, true)
	offerSpain: string;

	@JsonProperty('net_energy', Boolean, true)
	netEnergy: boolean;

	@JsonProperty('invoices', [String], true)
	invoices: string[];

	@JsonProperty('business', Boolean, true)
	business: boolean;

	@JsonProperty('energy_offer_laurete', NullableDeserializer, true)
	energyOfferLaurete: string;

	@JsonProperty('receive_offers', Boolean, true)
	receiveOffers: boolean;

	@JsonProperty('personalized_offers', Boolean, true)
	personalizedOffers: boolean;

	constructor(id?: number, zohoId?: string, serviceType: string = ServicesEnum.POWER, streetType: string = '', street: string = '',
				province: string = '', municipality: string = '', locality: string = '', number: string = '', block: string = '',
				stair: string = '', floor: string = '', door: string = '', zipCode: string = '', powerAccessRate: string = '',
				hiredPower: string = '', powerCups: string = '', gasAccessRate: string = '', gasCups: string = '',
				socialBonus: boolean = false, leadSource: string = '', groupSpain: string = '', offerId: string = '',
				offerUrl: string = '', offerSpain: string = '', netEnergy: boolean = false, invoices: string[] = [],
				business: boolean = false, energyOfferLaurete: string = '', receiveOffers: boolean = false, personalizedOffers: boolean = false) {
		this.id = id;
		this.zohoId = zohoId;
		this.serviceType = serviceType;
		this.streetType = streetType;
		this.street = street;
		this.province = province;
		this.municipality = municipality;
		this.locality = locality;
		this.number = number;
		this.block = block;
		this.stair = stair;
		this.floor = floor;
		this.door = door;
		this.zipCode = zipCode;
		this.powerAccessRate = powerAccessRate;
		this.hiredPower = hiredPower;
		this.powerCups = powerCups;
		this.gasAccessRate = gasAccessRate;
		this.gasCups = gasCups;
		this.socialBonus = socialBonus;
		this.leadSource = leadSource;
		this.groupSpain = groupSpain;
		this.offerId = offerId;
		this.offerUrl = offerUrl;
		this.offerSpain = offerSpain;
		this.netEnergy = netEnergy;
		this.invoices = invoices;
		this.business = business;
		this.energyOfferLaurete = energyOfferLaurete;
		this.receiveOffers = receiveOffers;
		this.personalizedOffers = personalizedOffers;
	}
}
