/* istanbul ignore file */
import HowWorksElement from './how-works-element';
import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('HowWorksElementImp')
export default class HowWorksElementImp implements HowWorksElement {

	@JsonProperty('title', String, true)
	title: string;

	@JsonProperty('subtitle', String, true)
	subtitle: string;

	@JsonProperty('image', String, true)
	image: string;

	constructor(title: string = '', subtitle: string = '', image: string = '') {
		this.title = title;
		this.subtitle = subtitle;
		this.image = image;
	}
}
