/* istanbul ignore file */
import Dictionary from 'model/dictionary';
import ValidatedForm from './validated-form';

export default class ValidatedFormImp implements ValidatedForm {

    formErrors: Dictionary<string>;
    isValid: boolean;

    constructor(formErrors: Dictionary<string> = {}, isValid: boolean = false) {
        this.formErrors = formErrors;
        this.isValid = isValid;
    }

}
