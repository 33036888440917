import React, {ReactElement} from 'react';
import {JsonConverter, JsonCustomConvert} from 'json2typescript';

@JsonConverter
export class ReactElementDeserializer implements JsonCustomConvert<ReactElement | ReactElement[]> {
	deserialize = (element: string | string[] = ''): ReactElement | ReactElement[] => {
		if (Array.isArray(element)) {
			return element.map((el: string) => <div dangerouslySetInnerHTML={{__html: el}}/>);
		} else {
			return <div dangerouslySetInnerHTML={{__html: element}}/>;
		}
	};

	serialize = (element: ReactElement | ReactElement[] = <div/>): string => {
		return String(element);
	};
}
