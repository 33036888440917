/* istanbul ignore file */
import React, {ReactElement} from 'react';
import OfferDetail from './offer-detail';

export default class OfferDetailImp implements OfferDetail {

	title: string;
	value: ReactElement;
	extraInfo?: string;

	constructor(title: string = '', value: ReactElement = <div/>, extraInfo: string = '') {
		this.title = title;
		this.value = value;
		this.extraInfo = extraInfo;
	}
}
