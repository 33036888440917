/* istanbul ignore file */
interface TypeFormConfig {
	CATEGORY_ID: string;
	INSCRIPTION_URL: string;
}

export const TYPE_FORM_CONSTANTS: TypeFormConfig = {
	CATEGORY_ID: '104',
	INSCRIPTION_URL: 'https://selectra.typeform.com/to/l3uGPB'
};
