import MainAction from '../model/main.action';
import {CategoryActionTypesEnum} from './types';
import CategoryState from './model/category.state';
import CategoryStateImp from './model/category.state.imp';
import {SetCategoryAction} from './actions';
import {ReduxTypesEnum} from '../model/types';

const initialState: CategoryState = new CategoryStateImp();

const categoryReducer = (state = initialState, action: MainAction) => {
    switch (action.type) {
        case ReduxTypesEnum.RESET: {
            return new CategoryStateImp();
        }
        case CategoryActionTypesEnum.SET_CATEGORY: {
            return Object.assign({}, state, {
                category: (action as SetCategoryAction).category
            });
        }
        default:
            return state;
    }
};

export default categoryReducer;
