import React, {FunctionComponent, ReactElement} from 'react';
import './loading.component.scss';
import {LazyLoadImage} from 'react-lazy-load-image-component';

interface LoadingComponentProps {
	message?: string;
}

const LoadingComponent: FunctionComponent<LoadingComponentProps> = ({ message }): ReactElement => {
	return <div className="loading-component">
		<LazyLoadImage src={process.env.PUBLIC_URL + '/loading.gif'} alt="loading"/>
		{ message && <span>{ message }</span> }
	</div>;
};

export default LoadingComponent;
