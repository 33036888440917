/* istanbul ignore file */
import Advantage from './advantage';
import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('AdvantageImp')
export default class AdvantageImp implements Advantage {

	@JsonProperty('title', String, true)
	title: string;

	@JsonProperty('text', String, true)
	text: string;

	@JsonProperty('image', String, true)
	image: string;

	constructor(title: string = '', text: string = '', image: string = '') {
		this.title = title;
		this.text = text;
		this.image = image;
	}
}
