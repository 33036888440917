import React, {FunctionComponent, ReactElement, useEffect} from 'react';
import './growl.component.scss';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import {HIDE_GROWL_MESSAGE_ACTION} from 'store/growl/actions';

const GrowlComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { messages } = useSelector((state: MainState) => state.growl);
	const dispatch = useDispatch();

	useEffect(() => {
		if (messages.length) {
			setTimeout(() => {
				dispatch(HIDE_GROWL_MESSAGE_ACTION);
			}, 3500)
		}
	}, [dispatch, messages]);

	return <div className="growl-component">
		{ messages.map((message: string, index: number) => {
			return <div className="growl-message" key={index}>
				<p className="bold no-margin">Error</p>
				<p className="no-margin">{ message }</p>
			</div>;
		}) }
	</div>;
};

export default GrowlComponent;
