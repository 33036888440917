/* istanbul ignore file */
interface FormsConfig {
    EMAIL_REG_EXP: RegExp;
    PHONE_REG_EXP: RegExp;
    DNI_REG_EXP: RegExp;
    CIF_REG_EXP: RegExp;
    NIE_REG_EXP: RegExp;
    FILE_TYPES: {
        IMAGE: string;
        PDF: string;
    }
}

export const FORMS_CONSTANTS: FormsConfig = {
    EMAIL_REG_EXP: /^(([^<>()\]\\.,;:\s@']+(\.[^<>()\]\\.,;:\s@']+)*)|('.+'))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PHONE_REG_EXP: /^(34|\+34|0034)?[\s|\-|.]?[6-9][\s|\-|.]?([0-9][\s|\-|.]?){8}$/,
    DNI_REG_EXP: /^(\d{8})([A-Z])$/,
    CIF_REG_EXP: /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/,
    NIE_REG_EXP: /^[XYZ]\d{7,8}[A-Z]$/,
    FILE_TYPES: {
        IMAGE: 'image/*',
        PDF: 'application/pdf'
    }
};
