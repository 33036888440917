/* istanbul ignore file */
interface AppConfig {
	ROUTES: {
		HOME: string;
		FAQ: string;
		CONTACT: string;
		INSCRIPTION_STEP_1: string;
		INSCRIPTION_STEP_1_DEFAULT: string;
		INSCRIPTION_STEP_2: string;
		INSCRIPTION_THANKS: string;
		INSCRIPTION_EDIT: string;
		FORBIDDEN: string;
		HIRE: string;
		HIRE_INDEX: string;
		HIRE_INDEX2: string;
		HIRE_NEW: string;
		HIRE_NEW_REG: string;
		HIRE_CUPS: string;
		HIRE_INFORMATION: string;
		HIRE_OFFER: string;
		HIRE_CLIENT: string;
		HIRE_THANKS: string;
		HIRE_GENERAL_ERROR: string;
		HIRE_OFFER_EXPIRED: string;
		UNSUBSCRIBE_MAIL: string;
	};
	AXIOS_CONFIG: {
		headers: {
			Authorization: string;
		}
	};
	ENDPOINTS: {
		CONTACTS: string;
		CATEGORIES: string;
		CATEGORIES_DEFAULT: string;
		CATEGORIES_GROUP_SPAIN: string;
		OFFER: string;
		OFFER_DEFAULT: string;
		USER_COUNT: string;
		VALIDATE_POWER_CUPS: string;
		VALIDATE_GAS_CUPS: string;
		CITIES_BY_ZIP_CODE: string;
	};
	ENDPOINT_BODIES: {
		CONTACT: any;
	};
	DEFAULT_CATEGORY_CODE: string;
}

export const APP_CONSTANTS: AppConfig = {
	ROUTES: {
		HOME: '/compracolectiva/view/home',
		FAQ: '/compracolectiva/view/home#faq',
		CONTACT: '/compracolectiva/view/home#contact',
		INSCRIPTION_STEP_1: '/compracolectiva/:categoryCode/inscripcion',
		INSCRIPTION_STEP_1_DEFAULT: '/compracolectiva/1/inscripcion',
		INSCRIPTION_STEP_2: '/compracolectiva/view/step2',
		INSCRIPTION_THANKS: '/compracolectiva/view/gracias',
		INSCRIPTION_EDIT: '/compracolectiva/view/review',
		FORBIDDEN: '/compracolectiva/view/error403',
		HIRE: '/compracolectiva/contratar/view',
		HIRE_INDEX: '/compracolectiva/contratar/view/index',
		HIRE_INDEX2: '/compracolectiva/contratar/view/index2',
		HIRE_NEW: '/compracolectiva/contratar/view/alta',
		HIRE_NEW_REG: '/compracolectiva/contratar/view/newReg',
		HIRE_CUPS: '/compracolectiva/contratar/view/cups',
		HIRE_INFORMATION: '/compracolectiva/contratar/view/information',
		HIRE_OFFER: '/compracolectiva/contratar/view/offer',
		HIRE_CLIENT: '/compracolectiva/contratar/view/client',
		HIRE_THANKS: '/compracolectiva/contratar/view/thanks',
		HIRE_GENERAL_ERROR: '/compracolectiva/contratar/view/error',
		HIRE_OFFER_EXPIRED: '/compracolectiva/contratar/view/offer-expired',
		UNSUBSCRIBE_MAIL: '/compracolectiva/view/unsubscribe-mail'
	},
	AXIOS_CONFIG: {
		headers: {
			Authorization: `${process.env.REACT_APP_API_TOKEN}`
		}
	},
	ENDPOINTS: {
		CONTACTS: `${process.env.REACT_APP_API_BASE_URL}/api/v1/contacts`,
		CATEGORIES: `${process.env.REACT_APP_API_BASE_URL}/api/v1/categories`,
		CATEGORIES_DEFAULT: `${process.env.REACT_APP_API_BASE_URL}/api/v1/categories/default`,
		CATEGORIES_GROUP_SPAIN: `${process.env.REACT_APP_API_BASE_URL}/api/v1/categories/by_group_spain`,
		OFFER: `${process.env.REACT_APP_API_BASE_URL}/api/v1/offers`,
		OFFER_DEFAULT: `${process.env.REACT_APP_API_BASE_URL}/api/v1/offers/default`,
		USER_COUNT: `${process.env.REACT_APP_API_BASE_URL}/api/v1/contacts/count`,
		VALIDATE_POWER_CUPS: 'https://europe-west1-energia-selectra-io.cloudfunctions.net/query?cups=',
		VALIDATE_GAS_CUPS: 'https://europe-west1-energia-selectra-io.cloudfunctions.net/query?type=gas&cups=',
		CITIES_BY_ZIP_CODE: `${process.env.REACT_APP_API_BASE_URL}/api/v1/cities/by_zipcode`
	},
	ENDPOINT_BODIES: {
		CONTACT: {
			data: {
				type: 'contact',
				attributes: {}
			}
		}
	},
	DEFAULT_CATEGORY_CODE: '1'
};
