/* istanbul ignore file */
import {FieldTypesEnum} from './field-types.enum';
import FormField from './form-field';

export default class FormFieldImp implements FormField {
    value: any;
    type: FieldTypesEnum;
    min?: number;
    max?: number;
    maxLength?: number;

    constructor(value?: any, type: FieldTypesEnum = FieldTypesEnum.STRING, min?: number, max?: number, maxLength?: number) {
        this.value = value;
        this.type = type;
        this.min = min;
        this.max = max;
        this.maxLength = maxLength;
    }
}
