import React from 'react';
import {JsonConverter, JsonCustomConvert} from 'json2typescript';
import OfferRate from 'components/hire/model/offer-rate';
import OfferRateImp from 'components/hire/model/offer-rate.imp';
import OfferDetailImp from 'components/hire/model/offer-detail.imp';
import {OFFER_CONSTANTS} from 'components/hire/components/offer/config/offer.config';

@JsonConverter
export class PowerRatesDeserializer implements JsonCustomConvert<OfferRate[]> {
	deserialize = (rates: any = {}): OfferRate[] => {
		return [
			new OfferRateImp(
				OFFER_CONSTANTS.POWER_RATES.NORMAL_20,
				[
					new OfferDetailImp('Término de consumo', <span><strong>{ rates.consumption_term20 || '-' }</strong> €/kWh</span>),
					new OfferDetailImp('Término de potencia', <span><strong>{ rates.day_power20 || '-' }</strong> €/kW día <sup className="day-cost">{ `${rates.power_term20 || '-'} €/kW año` }</sup></span>)
				]
			)
		];
	};

	serialize = (rates: OfferRate[] = []): string => {
		return rates.toString();
	};
}
