import {FunctionComponent, useEffect} from 'react';
import {useHistory} from 'react-router';

const ScrollToTopComponent: FunctionComponent = (): null => {
	const { location } = useHistory();

	useEffect(() => {
		try {
			window.scroll(0, 0);
		} catch (error) {
			window.scrollTo(0, 0);
		}
	}, [location.pathname, location.search]);

	return null;
};

export default ScrollToTopComponent;
