/* istanbul ignore file */
import User from './user';
import {JsonObject, JsonProperty} from 'json2typescript';
import Address from './address';
import AddressImp from './address.imp';

@JsonObject('UserImp')
export default class UserImp implements User {

	@JsonProperty('id', String, true)
	id?: string;

	@JsonProperty('token', String, true)
	token: string;

	@JsonProperty('name', String, true)
	name: string;

	@JsonProperty('surname', String, true)
	surname: string;

	@JsonProperty('phone', String, true)
	phone: string;

	@JsonProperty('email', String, true)
	email: string;

	@JsonProperty('company_name', String, true)
	companyName: string;

	@JsonProperty('company_activity', String, true)
	companyActivity: string;

	@JsonProperty('contact_person', String, true)
	contactPerson: string;

	@JsonProperty('company_national_id', String, true)
	companyNationalId: string;

	@JsonProperty('national_id', String, true)
	nationalId: string;

	@JsonProperty('bank_account', String, true)
	bankAccount: string;

	@JsonProperty('ip', String, true)
	ip: string;

	@JsonProperty('useragent', String, true)
	useragent: string;

	@JsonProperty('gclid', String, true)
	gclid: string | null;

	@JsonProperty('fbclid', String, true)
	fbclid: string | null;

	@JsonProperty('newsletter', Boolean, true)
	newsletter: boolean;

	@JsonProperty('address', AddressImp, true)
	address: Address;

	constructor(id?: string, token: string = '', name: string = '', surname: string = '', phone: string = '',
				email: string = '', companyName: string = '', companyActivity: string = '', contactPerson: string = '',
				companyNationalId: string = '', nationalId: string = '', bankAccount: string = '', ip: string = '',
				useragent: string = '', gclid: string = '', fbclid: string = '', newsletter: boolean = false, address: Address = new AddressImp()) {
		this.id = id;
		this.token = token;
		this.name = name;
		this.surname = surname;
		this.phone = phone;
		this.email = email;
		this.companyName = companyName;
		this.companyActivity = companyActivity;
		this.contactPerson = contactPerson;
		this.companyNationalId = companyNationalId;
		this.nationalId = nationalId;
		this.bankAccount = bankAccount;
		this.ip = ip;
		this.useragent = useragent;
		this.gclid = gclid;
		this.fbclid = fbclid;
		this.newsletter = newsletter;
		this.address = address;
	}
}
