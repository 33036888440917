import MainAction from '../model/main.action';
import {HireActionTypesEnum} from './types';
import HireState from './model/hire.state';
import HireStateImp from './model/hire.state.imp';
import {ReduxTypesEnum} from '../model/types';

const initialState: HireState = new HireStateImp();

const hireReducer = (state = initialState, action: MainAction) => {
	switch (action.type) {
		case ReduxTypesEnum.RESET: {
			return new HireStateImp();
		}
		case HireActionTypesEnum.SET_WRONG_USER: {
			return Object.assign({}, state, {
				wrongUser: true
			});
		}
		case HireActionTypesEnum.SET_WRONG_OFFER: {
			return Object.assign({}, state, {
				wrongOffer: true
			});
		}
		case HireActionTypesEnum.SET_EXPIRED_OFFER: {
			return Object.assign({}, state, {
				expiredOffer: true
			});
		}
		case HireActionTypesEnum.SET_CORRECT_USER: {
			return Object.assign({}, state, {
				wrongUser: false
			});
		}
		case HireActionTypesEnum.SET_CORRECT_OFFER: {
			return Object.assign({}, state, {
				wrongOffer: false
			});
		}
		case HireActionTypesEnum.SET_NOT_EXPIRED_OFFER: {
			return Object.assign({}, state, {
				expiredOffer: false
			});
		}
		default:
			return state;
	}
};

export default hireReducer;
