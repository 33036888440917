import React, {FormEvent, FunctionComponent, ReactElement, useState} from 'react';
import './call-me.component.scss';
import {HIDE_MODAL_ACTION} from 'store/modal/actions';
import icons from 'icons/sprite.svg';
import Dictionary from 'model/dictionary';
import FormField from 'services/forms/model/form-field';
import FormFieldImp from 'services/forms/model/form-field.imp';
import {FieldTypesEnum} from 'services/forms/model/field-types.enum';
import {handleBlur, handleChange, handleCheckboxChange, validateForm} from 'services/forms/services/forms.service';
import {callMe} from './services/call-me.service';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {useDispatch, useSelector} from 'react-redux';
import MainState from 'store/model/main.state';
import {showLoadLaterGrowlError} from 'services/growl.service';

const CallMeComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const user = useSelector((state: MainState) => state.user.user);
	const dispatch: Function = useDispatch();
	const [ showPrivacyPolicy, setShowPrivacyPolicy ] = useState(false);
	const [ showAdviserContact, setShowAdviserContact ] = useState(false);
	const [ form, setForm ] = useState<Dictionary<FormField>>({
		phone: new FormFieldImp(user.phone, FieldTypesEnum.PHONE),
		privacy: new FormFieldImp(false, FieldTypesEnum.BOOLEAN)
	});
	const [ formErrors, setFormErrors ] = useState<Dictionary<string>>({
		phone: '',
		privacy: ''
	});

	const submitPhone = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();

		if (showAdviserContact) {
			dispatch(HIDE_MODAL_ACTION);
		} else {
			const validatedForm = validateForm(form, formErrors);

			if (validatedForm.isValid) {
				dispatch(SHOW_LOADING_ACTION);

				callMe(user.id || '0', form.phone.value)
					.then(() => setShowAdviserContact(true))
					.catch(() => showLoadLaterGrowlError(dispatch))
					.finally(() => dispatch(HIDE_LOADING_ACTION));
			} else {
				setFormErrors(validatedForm.formErrors);
			}
		}
	};

	return <div className="call-me-component">
		<svg className="icon icon--24 icon--white clickable" onClick={() => dispatch(HIDE_MODAL_ACTION)}>
			<use xlinkHref={`${icons}#icon-cross`}/>
		</svg>
		<h1>¿Necesitas ayuda de un asesor?</h1>
		<h3>Te llamamos gratis</h3>
		<form noValidate={true} onSubmit={submitPhone}>
			<input
				className={formErrors.phone && 'input-error'} type="tel" name="phone"
				value={form.phone.value} placeholder="Introduce aquí tu teléfono"
				onChange={e => handleChange(e, form, setForm, formErrors, setFormErrors)}
				onBlur={e => handleBlur(e, true, form, formErrors, setFormErrors)}
			/>
			{ formErrors.phone && <div className="error phone-error">{ formErrors.phone }</div> }
			<div className="checkbox-container">
				<span className={`checkbox ${form.privacy.value && 'checked-checkbox'}`} onClick={() => handleCheckboxChange('privacy', form, setForm, formErrors, setFormErrors)}/>
				<label>
					Enviando el formulario estás aceptando nuestra
					<a href="https://selectra.es/politica-privacidad" target="_blank" rel="noopener noreferrer" onMouseEnter={() => setShowPrivacyPolicy(true)} onMouseLeave={() => setShowPrivacyPolicy(false)}>
						política de privacidad
					</a>.
					{ formErrors.privacy && <div className="error">{ formErrors.privacy }</div> }
				</label>
			</div>
			<div className={`privacy-policy ${showPrivacyPolicy && 'display-block'}`}>
				<p>De conformidad con lo dispuesto en la legislación vigente en materia de protección de
					datos, PROFIELECTRA SL (en adelante Selectra) le informan que los datos recabados en
					este formulario serán incluidos en un fichero temporal de dichas empresas, con la
					finalidad de gestionar su solicitud. Selectra garantiza la adopción de las medidas
					necesarias para asegurar el tratamiento confidencial de dichos datos y le informa de la
					posibilidad de ejercitar, conforme a dicha normativa, los derechos de acceso,
					rectificación, cancelación y oposición, dirigiendo un escrito a Selectra España, calle
					Conde de Vilches 20, Madrid o enviando un mail a <a href="mailto:datos@selectra.com">datos@selectra.com</a>.
				</p>
			</div>
			<div className="submit-container">
				<button className="btn btn--md" type="submit">{ showAdviserContact ? 'Gracias' : 'Te llamamos' }</button>
			</div>
			{ showAdviserContact && <div className="thanks">
                En breves momentos, un asesor se pondrá en contacto contigo.
			</div> }
		</form>
	</div>;
};

export default CallMeComponent;
