import React, {FunctionComponent, ReactElement, useEffect} from 'react';
import MainState from 'store/model/main.state';
import './modal.component.scss';
import {useDispatch, useSelector} from 'react-redux';
import {HIDE_MODAL_ACTION} from 'store/modal/actions';

const ModalComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { modal } = useSelector((state: MainState) => state);
	const dispatch = useDispatch();

	useEffect(() => {
		const closeOnEsc = (e: KeyboardEvent): void => {
			if (e.key === 'Escape') {
				dispatch(HIDE_MODAL_ACTION);
			}
		};

		window.addEventListener('keyup', closeOnEsc);
		return () => window.removeEventListener('keyup', closeOnEsc);
	}, [dispatch]);

	return <div className={`modal-component ${!modal.showModal && 'hidden'}`}>
		{ modal.content }
	</div>;
};

export default ModalComponent;
