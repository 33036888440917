import MainAction from '../model/main.action';
import {OfferActionTypesEnum} from './types';
import OfferState from './model/offer.state';
import OfferStateImp from './model/offer.state.imp';
import {SetOfferAction} from './actions';
import {ReduxTypesEnum} from '../model/types';

const initialState: OfferState = new OfferStateImp();

const offerReducer = (state = initialState, action: MainAction) => {
    switch (action.type) {
        case ReduxTypesEnum.RESET: {
            return new OfferStateImp();
        }
        case OfferActionTypesEnum.SET_OFFER: {
            return Object.assign({}, state, {
                offer: (action as SetOfferAction).offer
            });
        }
        default:
            return state;
    }
};

export default offerReducer;
