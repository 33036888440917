/* istanbul ignore file */
import Offer from './offer';
import {JsonObject, JsonProperty} from 'json2typescript';
import FaqElement from './faq-element';
import OfferRate from 'components/hire/model/offer-rate';
import FaqElementImp from './faq-element.imp';
import {GasRatesDeserializer} from './deserializers/gas-rates.deserializer';
import {PowerRatesDeserializer} from './deserializers/power-rates.deserializer';
import Advantage from './advantage';
import {ReactElementDeserializer} from './deserializers/react-element.deserializer';
import {ReactElement} from 'react';
import React from 'react';
import AdvantageImp from './advantage.imp';
import {StringNumberDeserializer} from './deserializers/string-number.deserializer';

const SELECTRA_PHONE = '91 123 12 60';

@JsonObject('OfferImp')
export default class OfferImp implements Offer {

	@JsonProperty('id', String, true)
	id: string;

	@JsonProperty('offer_type', String, true)
	offerType: string;

	@JsonProperty('lead_source_alta', String, true)
	leadSource: string;

	@JsonProperty('offer_url', String, true)
	offerUrl: string;

	@JsonProperty('title', String, true)
	title: string;

	@JsonProperty('paragraph1_with_category', ReactElementDeserializer, true)
	subtitle: string;

	@JsonProperty('company_logo', String, true)
	companyImage: string;

	@JsonProperty('saving_percentage_text', String, true)
	savingPercentage: string;

	@JsonProperty('leyend_price_taxes', ReactElementDeserializer, true)
	leyendPriceTaxes: ReactElement;

	@JsonProperty('leyend_price_taxes_2', ReactElementDeserializer, true)
	leyendPriceTaxes2: ReactElement;

	@JsonProperty('selectra_phone', String, true)
	selectraPhone: string;

	@JsonProperty('company_phone', String, true)
	companyPhone: string;

	@JsonProperty('advantages', [AdvantageImp], true)
	advantages: Advantage[];

	@JsonProperty('characteristics_checks', [String], true)
	characteristics: string[];

	@JsonProperty('accordions', [FaqElementImp], true)
	accordions: FaqElement[];

	@JsonProperty('power_offered', Boolean, true)
	powerOffered: boolean;

	@JsonProperty('gas_offered', Boolean, true)
	gasOffered: boolean;

	@JsonProperty('dual_offered', Boolean, true)
	dualOffered: boolean;

	@JsonProperty('rates', PowerRatesDeserializer, true)
	powerRates: OfferRate[];

	@JsonProperty('rates', GasRatesDeserializer, true)
	gasRates: OfferRate[];

	@JsonProperty('contract_conditions_url', String, true)
	contractConditions: string;

	@JsonProperty('power_especifications', String, true)
	powerSpecifications: string;

	@JsonProperty('company_name', String, true)
	companyName: string;

	@JsonProperty('paragraph1_thanks', ReactElementDeserializer, true)
	paragraph1Thanks: ReactElement;

	@JsonProperty('thanks_extra_button_text', String, true)
	thanksExtraButtonText: string;

	@JsonProperty('recommend_friend', Boolean, true)
	recommendFriend: boolean;

	@JsonProperty('energy_offer_laureate_alta', String, true)
	energyOfferLaureteAlta: string;

	@JsonProperty('expiration_date', String, true)
	expirationDate: string;

	@JsonProperty('contact_email_final', String, true)
	contactEmailFinal: string;

	@JsonProperty('offer_extra_conditions', ReactElementDeserializer, true)
	offerExtraConditions: ReactElement;

	@JsonProperty('receive_offers', String, true)
	receiveOffers: string;

	@JsonProperty('personalized_offers', String, true)
	personalizedOffers: string;

	@JsonProperty('min_light_rate_20', StringNumberDeserializer, true)
	minPower20: number;

	@JsonProperty('max_light_rate_20', StringNumberDeserializer, true)
	maxPower20: number;

	@JsonProperty('min_light_rate_21', StringNumberDeserializer, true)
	minPower21: number;

	@JsonProperty('max_light_rate_21', StringNumberDeserializer, true)
	maxPower21: number;

	@JsonProperty('supply_offered', String, true)
	supplyOffered: string;

	@JsonProperty('external_hire_url', String, true)
	externalHireUrl: string;

	constructor(id: string = '', offerType: string = '', leadSource: string = '', offerUrl: string = '',
				title: string = '', subtitle: string = '', companyImage: string = '', savingPercentage: string = '', leyendPriceTaxes: ReactElement = <div/>,
				leyendPriceTaxes2: ReactElement = <div/>, selectraPhone: string = SELECTRA_PHONE, companyPhone: string = '', advantages: Advantage[] = [],
				characteristics: string[] = [], accordions: FaqElement[] = [], powerOffered: boolean = false,
				gasOffered: boolean = false, dualOffered: boolean = false, powerRates: OfferRate[] = [], gasRates: OfferRate[] = [],
				contractConditions: string = '', powerSpecifications: string = '', companyName: string = '',
				paragraph1Thanks: ReactElement = <div/>, thanksExtraButtonText: string = '', recommendFriend: boolean = false,
				energyOfferLaureteAlta: string = '', expirationDate: string = '', contactEmailFinal: string = '',
				offerExtraConditions: ReactElement = <div/>, receiveOffers: string = '', personalizedOffers: string = '',
				minPower20: number = 0, maxPower20: number = 10, minPower21: number = 10.1, maxPower21: number = 15, supplyOffered: string = '', 
				externalHireUrl: string = '') {
		this.id = id;
		this.offerType = offerType;
		this.leadSource = leadSource;
		this.offerUrl = offerUrl;
		this.title = title;
		this.subtitle = subtitle;
		this.companyImage = companyImage;
		this.savingPercentage = savingPercentage;
		this.leyendPriceTaxes = leyendPriceTaxes;
		this.leyendPriceTaxes2 = leyendPriceTaxes2;
		this.selectraPhone = selectraPhone;
		this.companyPhone = companyPhone;
		this.advantages = advantages;
		this.characteristics = characteristics;
		this.accordions = accordions;
		this.powerOffered = powerOffered;
		this.gasOffered = gasOffered;
		this.dualOffered = dualOffered;
		this.powerRates = powerRates;
		this.gasRates = gasRates;
		this.contractConditions = contractConditions;
		this.powerSpecifications = powerSpecifications;
		this.companyName = companyName;
		this.paragraph1Thanks = paragraph1Thanks;
		this.thanksExtraButtonText = thanksExtraButtonText;
		this.recommendFriend = recommendFriend;
		this.energyOfferLaureteAlta = energyOfferLaureteAlta;
		this.expirationDate = expirationDate;
		this.contactEmailFinal = contactEmailFinal;
		this.offerExtraConditions = offerExtraConditions;
		this.receiveOffers = receiveOffers;
		this.personalizedOffers = personalizedOffers;
		this.minPower20 = minPower20;
		this.maxPower20 = maxPower20;
		this.minPower21 = minPower21;
		this.maxPower21 = maxPower21;
		this.supplyOffered = supplyOffered;
		this.externalHireUrl = externalHireUrl;
	}
}
