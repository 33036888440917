import React, { FormEvent, FunctionComponent, ReactElement } from "react";
import './unsubscribe-mail.component.scss';
import HeaderComponent from "components/header/header.component";
import Dictionary from "model/dictionary";
import queryString from 'query-string';
import { APP_CONSTANTS } from "config/app.config";
import { useHistory } from "react-router-dom";
import { unsubscribeMail } from "./services/unsubscribe-mail.service";
import { HIDE_LOADING_ACTION, SHOW_LOADING_ACTION } from "store/loading/actions";
import { useDispatch } from "react-redux";
import { showLoadLaterGrowlError } from "services/growl.service";
import { showLoadLaterGrowlSuccess } from "services/growl-success.service";


const UnsubscribeMailComponent: FunctionComponent = (): ReactElement => {
    const { push } = useHistory();
    const { id, token, aid } = queryString.parse(window.location.search) as Dictionary<string>;
    const dispatch: Function = useDispatch();

	const submitUnsubscribeMail = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();

        dispatch(SHOW_LOADING_ACTION);

        unsubscribeMail(id, token, aid)
        .then(() => push(`${APP_CONSTANTS.ROUTES.HOME}`))
        .then(() => showLoadLaterGrowlSuccess(dispatch))
        .catch(() => showLoadLaterGrowlError(dispatch))
        .finally(() => dispatch(HIDE_LOADING_ACTION));
	};

    return <div className="unsubscribe-mail-component">
        <HeaderComponent/>
        <div className="container top-unsubscribe-mail-component">
            <form onSubmit={submitUnsubscribeMail}>
                <div className="col-xs-12">
                    <h2 className="text-centered">Darse de baja</h2>
                    <p>
                        Haga clic en "Cancelar suscripción" para dejar de recibir correos electrónicos de Club Selectra.
                    </p>
                    <button className="btn btn--md call-me-button" type="submit">Cancelar subscripción</button>
                    <p></p>
                    <p>
                        <small>
                            De acuerdo con la ley, puede ejercer su derecho a retirar o modificar sus datos personales contactando <a href="mailto:datos@selectra.com">datos@selectra.com</a>
                        </small>
                    </p>
                </div>                  
            </form>
        </div>
    </div>;
};

export default UnsubscribeMailComponent;
