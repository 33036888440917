import {FORMS_CONSTANTS} from '../config/forms.config';
import {FieldTypesEnum} from '../model/field-types.enum';

export const validateEmail = (email: string): boolean => {
    return FORMS_CONSTANTS.EMAIL_REG_EXP.test(email);
};

export const validatePhone = (phone: string): boolean => {
    return FORMS_CONSTANTS.PHONE_REG_EXP.test(phone);
};

export const formatPhone = (phone: string): string => {
    if (phone && validatePhone(phone)) {
        let phoneCopy = phone;

        if (phone.startsWith('+34')) {
            phoneCopy = phone.substring(1);
        } else if (phone.startsWith('0034')) {
            phoneCopy = phone.substring(2);
        } else if (!phone.startsWith('0034') && !phone.startsWith('34')) {
            phoneCopy = '34' + phone;
        }

        phoneCopy = phoneCopy.replace(/\s/g, '');
        return phoneCopy;
    } else {
        return phone;
    }
};

export const validateCUPS = (CUPS: string): boolean => {
    const RegExPattern = /^ES[0-9]{16}[a-zA-Z]{2}[0-9]{0,1}[a-zA-Z]{0,1}$/;
    let status = false;

    if ((CUPS.match(RegExPattern)) && (CUPS !== '')) {
        const CUPS_16 = CUPS.substr(2, 16);
        const control = CUPS.substr(18, 2);
        const letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];

        const fmodv = fmod(CUPS_16, 529);
        const imod = parseInt(fmodv);
        const quotient = Math.floor(imod / 23);
        const remainder = imod % 23;

        const dc1 = letters[quotient];
        const dc2 = letters[remainder];
        status = (control === dc1 + dc2);
    } else {
        return false;
    }

    return status;
};

const fmod = (a: any, b: number): any => {
    return Number((a - (Math.floor(a / b) * b)).toPrecision(8));
};

export const formatCUPS = (CUPS: string, type: FieldTypesEnum): string => {
    CUPS = CUPS.replace(/\s/g, '').toUpperCase();

    if (CUPS && type === FieldTypesEnum.CUPS_POWER) {
        if (CUPS.substr(0, 6) === 'ES0021') {
            return CUPS.substr(0, 20) + '1F';
        } else if (CUPS.substr(0, 6) === 'ES0022' || CUPS.substr(0, 6) === 'ES0039') {
            return CUPS.substr(0, 20) + '1P';
        } else {
            return CUPS.substr(0, 20) + '0F';
        }
    } else {
        return CUPS;
    }
};

export const validateBankAccount = (inputBankAccount: string): boolean => {
    const bankAccount = inputBankAccount.toUpperCase().replace(/\s/g,'');

    let letter1: string, letter2: string, num1: number, num2: number, isBanaux: string;

    if (bankAccount.length !== 24) {
        return false;
    }

    letter1 = bankAccount.substring(0, 1);
    letter2 = bankAccount.substring(1, 2);
    num1 = getNumBankAccount(letter1);
    num2 = getNumBankAccount(letter2);

    isBanaux = String(num1) + String(num2) + bankAccount.substring(2);
    isBanaux = isBanaux.substring(6) + isBanaux.substring(0, 6);

    return module97(isBanaux) === 1;
};

const getNumBankAccount = (letter: string): number => {
    const allLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return allLetters.search(letter) + 10;
};

const module97 = (bankAccount: string): number => {
    const parts = Math.ceil(bankAccount.length / 7);
    let remainer = '';

    for (let i = 1; i <= parts; i++) {
        remainer = String(parseFloat(remainer + bankAccount.substr((i - 1) * 7, 7)) % 97);
    }

    return parseInt(remainer);
};

export const validateNationalId = (identifier: string): boolean => {
    identifier = identifier.toUpperCase().replace(/\s/, '');
    const type = spainIdType(identifier);

    switch (type) {
        case 'dni':
            return validDNI(identifier);
        case 'nie':
            return validNIE(identifier);
        case 'cif':
            return validCIF(identifier);
        default:
            return false;
    }
};

const spainIdType = (identifier: string): string => {
    if (identifier.match(FORMS_CONSTANTS.DNI_REG_EXP)) {
        return 'dni';
    }
    if (identifier.match(FORMS_CONSTANTS.CIF_REG_EXP)) {
        return 'cif';
    }
    if (identifier.match(FORMS_CONSTANTS.NIE_REG_EXP)) {
        return 'nie';
    }
    return '';
};

const validDNI = (dni: string): boolean => {
    const dniLetters = "TRWAGMYFPDXBNJZSQVHLCKE";
    const letter = dniLetters.charAt(parseInt(dni,10) % 23);
    return letter === dni.charAt(8);
};

const validNIE = (nie: string): boolean => {
    let niePrefix = nie.charAt(0);

    switch (niePrefix) {
        case 'X': niePrefix = '0'; break;
        case 'Y': niePrefix = '1'; break;
        case 'Z': niePrefix = '2'; break;
    }

    return validDNI(niePrefix + nie.substr(1));

};

export const validCIF = (cif: string): boolean => {
    const match = cif.match(FORMS_CONSTANTS.CIF_REG_EXP);

    /* istanbul ignore else */
    if (match) {
        const letter = match[1], number = match[2], control = match[3];
        let evenSum = 0, oddSum = 0, n;

        for (let i = 0; i < number.length; i++) {
            n = parseInt(number[i], 10);

            if (i % 2 === 0) {
                n *= 2;
                oddSum += n < 10 ? n : n - 9;
            } else {
                evenSum += n;
            }
        }

        const controlDigit = (10 - Number((evenSum + oddSum).toString().substr(-1)));
        const controlLetter = 'JABCDEFGHI'.substr(controlDigit, 1);

        if (letter.match(/[ABEH]/)) {
            return control === String(controlDigit);
        } else if (letter.match(/[KPQS]/)) {
            return control === controlLetter;
        } else {
            return control === String(controlDigit) || control === controlLetter;
        }
    }

    return false;
};
