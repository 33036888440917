/* istanbul ignore file */
interface OfferConfig {
	FORBIDDEN_POWER_ACCESS_RATE: string;
	POWER_RATES: {
		NORMAL_20: string;
	}
	GAS_RATES: {
		RL_1: string;
		RL_2: string;
	}
}

export const OFFER_CONSTANTS: OfferConfig = {
	FORBIDDEN_POWER_ACCESS_RATE: '3',
	POWER_RATES: {
		NORMAL_20: '2.0TD'
	},
	GAS_RATES: {
		RL_1: 'RL.1',
		RL_2: 'RL.2'
	}
};
