import React, {FunctionComponent, lazy, ReactElement, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {APP_CONSTANTS} from 'config/app.config';
import {BrowserRouter} from 'react-router-dom';
import {SpinnerComponent} from 'react-element-spinner/build';
import MainState from 'store/model/main.state';
import {useSelector} from 'react-redux';
import LoadingComponent from 'components/loading/loading.component';
import ModalComponent from 'components/modal/modal.component';
import ScrollToTopComponent from 'components/scroll-to-top/scroll-to-top.component';
import GrowlComponent from '../growl/growl.component';
import CookiesComponent from '../cookies/cookies.component';
import FooterComponent from 'components/footer/footer.component';
import UnsubscribeMailComponent from 'components/unsubscribe-mail/unsubscribe-mail.component';
import GrowlSuccessComponent from 'components/growl-success/growl-success.component';

const HomeComponent = lazy(() => import('components/home/home.component'));
const InscriptionComponent = lazy(() => import('../inscription/inscription.component'));
const HireComponent = lazy(() => import('components/hire/hire.component'));

const MainComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const loading: boolean = useSelector((state: MainState) => state.loading.loading);

	return <Suspense fallback={<SpinnerComponent loading={true} position="global" backgroundColor="black" customSpinner={<LoadingComponent/>}/>}>
			<BrowserRouter>
			<ScrollToTopComponent/>
			<SpinnerComponent loading={loading} position="global" backgroundColor="black" customSpinner={<LoadingComponent/>}/>
			<ModalComponent/>
			<GrowlComponent/>
			<GrowlSuccessComponent/>
			<CookiesComponent/>
			<Switch>
				<Route path={APP_CONSTANTS.ROUTES.HOME} component={HomeComponent} />
				<Route path={APP_CONSTANTS.ROUTES.INSCRIPTION_STEP_1} component={InscriptionComponent} />
				<Route path={APP_CONSTANTS.ROUTES.INSCRIPTION_STEP_2} component={InscriptionComponent} />
				<Route path={APP_CONSTANTS.ROUTES.INSCRIPTION_THANKS} component={InscriptionComponent} />
				<Route path={APP_CONSTANTS.ROUTES.INSCRIPTION_EDIT} component={InscriptionComponent} />
				<Route path={APP_CONSTANTS.ROUTES.FORBIDDEN} component={InscriptionComponent} />
				<Route path={APP_CONSTANTS.ROUTES.HIRE} component={HireComponent} />
				<Route path={APP_CONSTANTS.ROUTES.UNSUBSCRIBE_MAIL} component={UnsubscribeMailComponent} />
				{<Redirect from='/anae' to={`${APP_CONSTANTS.ROUTES.HOME}?c=103`}/>}
				<Redirect to={APP_CONSTANTS.ROUTES.HOME}/>
			</Switch>
			<FooterComponent/>
		</BrowserRouter>
	</Suspense>
};

export default MainComponent;
