import React, {Fragment, FunctionComponent, ReactElement, useState} from 'react';
import './header.component.scss';
import {APP_CONSTANTS} from 'config/app.config';
import {Link} from 'react-router-dom';
import icons from 'icons/sprite.svg';
import { HashLink } from 'react-router-hash-link';
import {useDispatch, useSelector} from 'react-redux';
import {showCallMeModal} from 'services/call-me-modal.service';
import MainState from 'store/model/main.state';
import {TYPE_FORM_CONSTANTS} from 'config/typeform.config';

const HeaderComponent: FunctionComponent = (): ReactElement => {
	/* istanbul ignore next */
	const { category } = useSelector((state: MainState) => state.category);
	const dispatch: Function = useDispatch();
	const [ showMenu, setShowMenu ] = useState(false);

	const getLinks = (): ReactElement => {
		return <Fragment>
			<Link to={`${APP_CONSTANTS.ROUTES.HOME}${category.id && `?c=${category.id}`}`}>Inicio</Link>
			{ category.id === TYPE_FORM_CONSTANTS.CATEGORY_ID ? <a href={TYPE_FORM_CONSTANTS.INSCRIPTION_URL} target="_blank" rel="noopener noreferrer">
				{ category.menuInscribeButton || 'Apúntate' }
			</a> : <Link to={APP_CONSTANTS.ROUTES.INSCRIPTION_STEP_1.replace(':categoryCode', String(category.id || APP_CONSTANTS.DEFAULT_CATEGORY_CODE))}>
				{ category.menuInscribeButton || 'Apúntate' }
			</Link> }
			<HashLink to={APP_CONSTANTS.ROUTES.FAQ}>F.A.Q.</HashLink>
			{ category.contactPhone ? <a className="phone-link" href={`tel:${category.contactPhone}`}>Contacto</a> : <HashLink className="phone-link" to={APP_CONSTANTS.ROUTES.CONTACT}>Contacto</HashLink> }
			{ category.contactPhone ? <span className="call-me" onClick={() => showCallMeModal(dispatch)}>Contacto</span> : <HashLink className="call-me" to={APP_CONSTANTS.ROUTES.CONTACT}>Contacto</HashLink> }
		</Fragment>;
	};

	return <div className="header-component">
		<div className="container">
			<div className="row">
				<div className="col-xs-9 col-md-3">
					<Link to={`${APP_CONSTANTS.ROUTES.HOME}${category.id && `?c=${category.id}`}`}>
						<img alt="logo" src={category.headerLogo || `${process.env.PUBLIC_URL}/selectra-light-logo.svg`} />
					</Link>
				</div>
				<div className="col-xs-3 col-md-9 links">
					{ getLinks() }
					<svg className="icon icon--32 icon--white" onClick={() => setShowMenu(!showMenu)}>
						<use xlinkHref={`${icons}#icon-${showMenu ? 'cross' : 'hamburger-menu'}`}/>
					</svg>
				</div>
			</div>
		</div>
		{ showMenu && <div className="mobile-links" onClick={() => setShowMenu(false)}>
			{ getLinks() }
		</div> }
	</div>;
};

export default HeaderComponent;
