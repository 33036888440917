import {JsonConverter, JsonCustomConvert} from 'json2typescript';

@JsonConverter
export class StringNumberDeserializer implements JsonCustomConvert<number | null> {
	deserialize = (element: string): number => {
		return Number(element);
	};

	serialize = (element: number): string => {
		return String(element);
	};
}
