/* istanbul ignore file */
import OfferState from './offer.state';
import Offer from 'model/offer';
import OfferImp from 'model/offer.imp';

export default class OfferStateImp implements OfferState {
    offer: Offer;

    constructor(offer: Offer = new OfferImp()) {
        this.offer = offer;
    }
}
