/* istanbul ignore file */
import OfferRate from './offer-rate';
import OfferDetail from './offer-detail';

export default class OfferRateImp implements OfferRate {

	rate: string;
	offerDetails: OfferDetail[];

	constructor(rate: string = '', offerDetails: OfferDetail[] = []) {
		this.rate = rate;
		this.offerDetails = offerDetails;
	}
}
